import { BILLING_PLAN_PERIODS } from './../constants/index';
import dayjs from 'dayjs'
import { UserProfile } from 'src/types/common'

export const formatCurrency = (value: number) => {
  return Number(value.toFixed(1)).toLocaleString()
}

export const formatTime = (value: string | undefined, format = 'YYYY/MM/DD HH:mm:ss') => {
  if (!value || !dayjs(value).isValid()) return ''

  return dayjs(value).format(format)
}

export const formatMonthYear = (value: string | undefined) => {
  return formatTime(value, 'YYYY/MM')
}

export const formatDate = (value: string | undefined) => {
  return formatTime(value, 'YYYY/MM/DD')
}

export const formatTimeNow = (format = 'YYYY/MM/DD HH:mm:ss') => {
  return dayjs().format(format)
}

export const formatTimeMinutes = (value: string | null | undefined, format = 'YYYY/MM/DD HH:mm') => {
  if (!value || !dayjs(value).isValid()) return ''

  return dayjs(value).format(format)
}

export function formatSecondsToMMSS(totalSeconds: number) {
  if (typeof totalSeconds !== 'number' || isNaN(totalSeconds)) {
    return ''
  }

  const minutes = String(Math.floor(totalSeconds / 60)).padStart(2, '0')
  const seconds = String(totalSeconds % 60).padStart(2, '0')

  return `${minutes}:${seconds}`
}

export function isBrowser() {
  return typeof window !== 'undefined'
}

export function isProduction() {
  return process.env.NODE_ENV === 'production'
}

export const convertThreeColumn = <T>(arr: T[]) => {
  const totalColumns = 3

  const result: Array<T[]> = []
  let i = 0
  while (i < totalColumns) {
    result[i] = []
    i++
  }
  arr.forEach((item: T, index) => {
    const columnAt = index % totalColumns
    result[columnAt].push(item)
  })

  return result
}

export const formatPercentage = (value: number | undefined) => {
  if (!value) return '-'

  return Math.floor(value * 100)
}

export const getScoreText = (score: number | undefined) => {
  if (!score) return ''
  if (score >= 0.9) {
    return 'S'
  } else if (score >= 0.8 && score < 0.9) {
    return 'A'
  } else if (score >= 0.7 && score < 0.8) {
    return 'B'
  } else if (score >= 0.6 && score < 0.7) {
    return 'C'
  } else {
    return 'D'
  }
}

export function splitDecimalNumber<
  DefaultIntegerPartType extends string | number,
  DefaultDecimalPartType extends string | number
>({
  value,
  fractionDigits = 1,
  defaultIntegerPart = 0 as any,
  defaultDecimalPart = 0 as any
}: {
  value: number | undefined
  fractionDigits?: number
  defaultIntegerPart?: DefaultIntegerPartType
  defaultDecimalPart?: DefaultDecimalPartType
}): {
  integerPart: DefaultIntegerPartType | number
  decimalPart: DefaultDecimalPartType | number
} {
  if (typeof value !== 'number' || isNaN(value))
    return {
      integerPart: defaultIntegerPart,
      decimalPart: defaultDecimalPart
    }
  const parts = value.toFixed(fractionDigits).toString().split('.') as [string, string]

  const integerPart = parseInt(parts[0], 10)
  const decimalPart = parseInt(parts[1], 10)

  return {
    integerPart: integerPart,
    decimalPart: decimalPart
  }
}

export const getScoreByText = (scoreText: string | undefined) => {
  if (!scoreText) return 0
  if (scoreText === 'S') {
    return 90
  } else if (scoreText === 'A') {
    return 80
  } else if (scoreText === 'B') {
    return 70
  } else if (scoreText === 'C') {
    return 60
  } else {
    return 0
  }
}

export const checkPrivilegeType = (userProfile: UserProfile | undefined, privilegeType: string) => {
  if (!userProfile) return
  const foundPrivilege = userProfile.company.privileges.find(privilege => privilege.privilegeType === privilegeType)

  return !!foundPrivilege
}

export const contractPeriodLabel = (period: string | null | undefined) => {
  switch (period) {
    case BILLING_PLAN_PERIODS.monthly:
      return '月契約'
    case BILLING_PLAN_PERIODS.yearly:
      return '年契約'
    default:
      return '-'
  }
}

export const isMobile = () => {
  const userAgent = typeof window !== "undefined" ? window.navigator.userAgent : "";

  return /iPhone|iPad|iPod|Android/i.test(userAgent);
};
