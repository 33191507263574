import axios from 'src/utils/axios'
import { RawAxiosResponseHeaders, AxiosResponseHeaders } from 'axios'
import { PaginationMeta, LoginForm, UserProfile } from 'src/types/common'
import { User } from 'src/types/management'
import { ManagementUserFormData } from 'src/types/management/form'
import { EXAM_RESULT_VALUE } from 'src/constants'

export type ManagementUserSearch = {
  page?: number
  per?: number
  'q[s]'?: string
  'q[employeeNoCont]'?: string
  'q[nameCont]'?: string
  'q[createdAtEq]'?: string | null
  'q[emailCont]'?: string
  'q[roleIdIn]'?: number[]
  'q[departmentsIdIn]'?: number[]
  'q[departmentGroupsIdIn]'?: number[]
  'q[examResultEq]'?: EXAM_RESULT_VALUE | null // 研修テスト結果
  'q[entryEq]'?: string
}

export type ManagementUsersResponseType = {
  users: User[]
  meta: PaginationMeta
}

export type FailureRecord = {
  rowId: number
  inputCsv: string
  errorMessages: string[]
}

export type ImportedUser = {
  processing: string
  employeeNo: string
  name: string
  email: string
  roleName: string
  departmentGroupsNames: string[]
  departmentNames: string[]
}

export type ConfirmedUser = {
  inputCsv: string
  duplicateEmail: boolean
}

export type UserConfirmationRecord = {
  users: ImportedUser[]
}

export const login = async (
  data: LoginForm
): Promise<{
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders
  data: { data: UserProfile }
}> => {
  const res = await axios.post('/management/login', data)

  return res
}

export const logout = async () => {
  const res = await axios.delete('/management/logout')

  return res
}

export const index = async ({ params }: { params: ManagementUserSearch }): Promise<ManagementUsersResponseType> => {
  const { data } = await axios.get(`/management/users`, { params })

  return data
}

export const all = async (): Promise<ManagementUsersResponseType> => {
  const { data } = await axios.get(`/management/users/all`)

  return data
}

export const show = async (userId: number | string): Promise<User> => {
  const { data } = await axios.get(`/management/users/${userId}`)

  return data.user
}

export const create = async (user: ManagementUserFormData): Promise<User> => {
  const { data } = await axios.post(`/management/users`, { user })

  return data.user
}

export const update = async (userId: number, user: ManagementUserFormData): Promise<User> => {
  const { data } = await axios.put(`/management/users/${userId}`, { user })

  return data.user
}

export const confirmBulkCreation = async (sendData: FormData): Promise<UserConfirmationRecord> => {
  const { data } = await axios.post(`/management/user_inserts/confirm`, sendData)

  return data
}

export const importBulkCreation = async (sendData: ImportedUser[]): Promise<any> => {
  const { data } = await axios.post(`/management/user_inserts/import`, sendData)

  return data
}

export const getFile = async (): Promise<Blob> => {
  const { data } = await axios.get(`/management/user_inserts/download`, {
    responseType: 'blob'
  })

  return data
}

export const password = async (user: { currentPassword: string; newPassword: string; confirmPassword: string }) => {
  const { data } = await axios.put('/management/user/password', { ...user })

  return data
}

export const destroy = async (userId: number) => {
  const { data } = await axios.delete(`/management/users/${userId}`)

  return data
}
