import HomeOutline from 'mdi-material-ui/HomeOutline'
import BusinessIcon from '@mui/icons-material/Business';
import CampaignIcon from '@mui/icons-material/Campaign'

import { VerticalNavItemsType } from 'src/@core/layouts/types'

const navigation = (): VerticalNavItemsType => {
  return [
    {
      title: '研修コンテンツ',
      icon: HomeOutline as any,
      path: '/admin/training_contents'
    },
    {
      title: '企業一覧',
      icon: BusinessIcon as any,
      path: '/admin/companies'
    },
    {
      title: 'お知らせ一覧',
      icon: CampaignIcon as any,
      path: '/admin/announcements'
    }
  ]
}

export default navigation
