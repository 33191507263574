import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    text: {
      primary: '#000000',
    },
    primary: {
      main: '#00BCD4',
      dark: '#00ACC1',
      light: '#26C6DA',
    },
    secondary: {
      main: '#9C27B0',
      dark: '#7B1FA2',
      light: '#BA68C8',
    },
    info: {
      main: '#0288D1',
      dark: '#01579B',
      light: '#03A9F4',
    },
    success: {
      main: '#2E7D32',
      dark: '#1B5E20',
      light: '#4CAF50',
    },
    warning: {
      main: '#00BCD4',
      dark: '#E65100',
      light: '#FDEDED',

    },
    error: {
      main: '#ED6C02',
      dark: '#C62828',
      light: '#FF9800',
    },
  },
  typography: {
    allVariants: {
      whiteSpace: 'pre-line',
    },
  }
})

export default theme;
