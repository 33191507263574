import axios from 'src/utils/axios'

export interface FormUpdatePasswordType {
  password: string
  passwordConfirmation: string
}

export const updatePassword = async (data: FormUpdatePasswordType) => {
  const res = await axios.put('/account/user/password', data)

  return res
}
